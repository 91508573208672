import axios from 'axios';

// Determine a URL base com base no ambiente
const baseURL =
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_BASE_URL_PROD
		: process.env.REACT_APP_BASE_URL_DEV;

const api = axios.create({
	baseURL: baseURL,
});

export default api;
