import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { textStyle } from './styles';
import { BanksDataProps } from './types';

const BanksResult: React.FC<BanksDataProps> = ({ data }) => {
	return (
		<VStack spacing={4} align="stretch">
			{data.map((bank, index) => (
				<Box
					key={bank.ispb || index}
					borderWidth={1}
					borderRadius="lg"
					p={4}
				>
					<Text {...textStyle}>
						Código do Banco: {bank.code || 'N/A'}
					</Text>
					<Text {...textStyle}>Nome do Banco: {bank.name}</Text>
					<Text {...textStyle}>Nome Completo: {bank.fullName}</Text>
					<Text {...textStyle}>ISPB: {bank.ispb}</Text>
				</Box>
			))}
		</VStack>
	);
};

export default BanksResult;
