import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { textStyle } from './styles';
import { CepDataProps } from './types';

const CepResult: React.FC<CepDataProps> = (props) => {
	return (
		<Box>
			<Text {...textStyle}>CEP: {props.data.cep}</Text>
			<Text {...textStyle}>Rua: {props.data.street}</Text>
			<Text {...textStyle}>Bairro: {props.data.neighborhood}</Text>
			<Text {...textStyle}>Cidade: {props.data.city}</Text>
			<Text {...textStyle}>Estado: {props.data.state}</Text>
			<Text {...textStyle}>Serviço: {props.data.service}</Text>
		</Box>
	);
};

export default CepResult;
