import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { boxStyle, headingStyle, textStyle } from './styles';
import { ResultProps } from './types';
import CepResult from '../CepResult';
import BankResult from '../BankResult';
import CnpjResult from '../CnpjResult';
import BanksResult from '../BanksResult';
import { ConsultaOption } from '../../utils/constants';
import CepResultV2 from '../CepV2Result';

const Result: React.FC<ResultProps> = ({ result, selectedOption }) => {
	return (
		<Box {...boxStyle}>
			<Heading {...headingStyle}>Resultado da Consulta</Heading>
			{result.success ? (
				<>
					{selectedOption === ConsultaOption.CEP && (
						<CepResult data={result.data} />
					)}
					{selectedOption === ConsultaOption.CEPV2 && (
						<CepResultV2 data={result.data} />
					)}
					{selectedOption === ConsultaOption.BANK && (
						<BankResult data={result.data} />
					)}
					{selectedOption === ConsultaOption.CNPJ && (
						<CnpjResult data={result.data} />
					)}
					{selectedOption === ConsultaOption.BANKS && (
						<BanksResult data={result.data} />
					)}
				</>
			) : (
				<Text {...textStyle}>{result.message}</Text>
			)}
		</Box>
	);
};

export default Result;
