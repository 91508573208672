import React, { useState, useEffect } from 'react';
import { Button, FormControl, Input } from '@chakra-ui/react';
import { buttonStyle, inputStyle } from './styles';
import { DynamicFormProps } from './types';
import { ConsultaOption } from '../../utils/constants';
import * as masks from '../../utils/masks';

const DynamicForm: React.FC<DynamicFormProps> = ({
	selectedOption,
	onSearch,
	requiresInput,
}) => {
	const [inputValue, setInputValue] = useState('');

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const cleanValue = masks.removeFormatting(inputValue);
		onSearch(cleanValue);
	};

	useEffect(() => {
		setInputValue('');
	}, [selectedOption]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		let maskedValue = value;

		switch (selectedOption) {
			case ConsultaOption.CEP:
				maskedValue = masks.insertMaskInCep(value);
				break;
			case ConsultaOption.CEPV2:
				maskedValue = masks.insertMaskInCep(value);
				break;
			case ConsultaOption.CNPJ:
				maskedValue = masks.insertMaskInCnpj(value);
				break;
			case ConsultaOption.BANK:
				maskedValue = masks.insertMaskInBank(value);
				break;
			default:
				maskedValue = value;
		}

		setInputValue(maskedValue);
	};

	return (
		<form onSubmit={handleSubmit}>
			<FormControl id="input" isRequired={requiresInput}>
				{requiresInput && (
					<Input
						type="text"
						value={inputValue}
						onChange={handleInputChange}
						{...inputStyle}
					/>
				)}
			</FormControl>
			<Button type="submit" {...buttonStyle}>
				Buscar
			</Button>
		</form>
	);
};

export default DynamicForm;
