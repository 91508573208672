// src/utils/masks.ts

export const insertMaskInCep = (cep: string) => {
	return cep
		.replace(/\D/g, '')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{3})\d+?$/, '$1');
};

export const insertMaskInCnpj = (cnpj: string) => {
	return cnpj
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1');
};

export const insertMaskInBank = (bank: string) => {
	return bank.replace(/\D/g, '').slice(0, 3);
};

export const removeFormatting = (value: string): string => {
	return value.replace(/\D/g, '');
};

// Adicione outras funções de máscara conforme necessário
