import { useState } from 'react';
import { AppState } from './types';

export const useAppState = () => {
	const [selectedOption, setSelectedOption] =
		useState<AppState['selectedOption']>(null);
	const [result, setResult] = useState<AppState['result']>(null);

	return { selectedOption, setSelectedOption, result, setResult };
};
