import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import Sidebar from '../../components/Sidebar';
import DynamicForm from '../../components/DynamicForm';
import Result from '../../components/Result';
import { flexStyle, boxStyle, headingStyle } from './styles';
import { useAppState } from '../../hooks/useAppState';
import { useSearch } from '../../hooks/useSearch';
import { ConsultaOption } from '../../utils/constants';

const App: React.FC = () => {
	const { selectedOption, setSelectedOption, result, setResult } =
		useAppState();
	const handleSearch = useSearch(selectedOption, setResult);

	const handleOptionChange = (option: string) => {
		setSelectedOption(option);
		setResult(null);
	};

	const exibiraInput = selectedOption === ConsultaOption.BANKS ? false : true;

	return (
		<Flex {...flexStyle}>
			<Sidebar setSelectedOption={handleOptionChange} />
			<Box {...boxStyle}>
				<Heading {...headingStyle}>
					{selectedOption ||
						'Selecione uma opção para começar a consulta.'}
				</Heading>
				{selectedOption && (
					<>
						<DynamicForm
							selectedOption={selectedOption}
							onSearch={handleSearch}
							resetInput={() => handleOptionChange('')}
							requiresInput={exibiraInput}
						/>
						{result && (
							<Result
								result={result}
								selectedOption={selectedOption}
							/>
						)}
					</>
				)}
			</Box>
		</Flex>
	);
};

export default App;
