import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { textStyle } from './styles';
import { CnpjDataProps } from './types';

const CnpjResult: React.FC<CnpjDataProps> = (props) => {
	return (
		<Box>
			<Text {...textStyle}>CNPJ: {props.data.cnpj}</Text>
			<Text {...textStyle}>Razão Social: {props.data.razao_social}</Text>
			<Text {...textStyle}>
				Nome Fantasia: {props.data.nome_fantasia}
			</Text>
			<Text {...textStyle}>Município: {props.data.municipio}</Text>
			<Text {...textStyle}>UF: {props.data.uf}</Text>
		</Box>
	);
};

export default CnpjResult;

/*
{
  "success": true,
  "data": {
    "uf": "PE",
    "cep": "56550000",
    "qsa": [],
    "cnpj": "02887071000183",
    "pais": null,
    "email": null,
    "porte": "MICRO EMPRESA",
    "bairro": "NEGRAS",
    "numero": "S/N",
    "ddd_fax": "",
    "municipio": "ITAIBA",
    "logradouro": "NOSSA SENHORA DA CONCEICAO",
    "cnae_fiscal": 4541201,
    "codigo_pais": null,
    "complemento": "",
    "codigo_porte": 1,
    "razao_social": "NOELMA CIRIACO DA GAMA",
    "nome_fantasia": "JUNIOR BICIMOTO",
    "capital_social": 0,
    "ddd_telefone_1": "",
    "ddd_telefone_2": "",
    "opcao_pelo_mei": false,
    "descricao_porte": "",
    "codigo_municipio": 2449,
    "cnaes_secundarios": [
      {
        "codigo": 0,
        "descricao": ""
      }
    ],
    "natureza_juridica": "Empresário (Individual)",
    "situacao_especial": "",
    "opcao_pelo_simples": false,
    "situacao_cadastral": 8,
    "data_opcao_pelo_mei": null,
    "data_exclusao_do_mei": null,
    "cnae_fiscal_descricao": "Comércio por atacado de motocicletas e motonetas",
    "codigo_municipio_ibge": 2607505,
    "data_inicio_atividade": "1998-11-30",
    "data_situacao_especial": null,
    "data_opcao_pelo_simples": "2007-07-01",
    "data_situacao_cadastral": "2018-09-12",
    "nome_cidade_no_exterior": "",
    "codigo_natureza_juridica": 2135,
    "data_exclusao_do_simples": "2017-05-31",
    "motivo_situacao_cadastral": 1,
    "ente_federativo_responsavel": "",
    "identificador_matriz_filial": 1,
    "qualificacao_do_responsavel": 50,
    "descricao_situacao_cadastral": "BAIXADA",
    "descricao_tipo_de_logradouro": "PRACA",
    "descricao_motivo_situacao_cadastral": "EXTINCAO POR ENCERRAMENTO LIQUIDACAO VOLUNTARIA",
    "descricao_identificador_matriz_filial": "MATRIZ"
  }
}
*/
