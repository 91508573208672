import { Box, ListItem } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const SidebarContainer = styled(Box)`
	width: 200px;
	background-color: #e2e8f0;
	padding: 1.25rem;
`;

export const OptionItem = styled(ListItem)`
	cursor: pointer;
	padding: 0.5rem;
	border-radius: var(--chakra-radii-md);

	&:hover {
		background-color: #cbd5e0;
	}
`;

export const listStyle = {
	spacing: 2,
};
