import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { textStyle } from './styles';
import { CepDataV2Props } from './types';

const CepResultV2: React.FC<CepDataV2Props> = (props) => {
	return (
		<Box>
			<Text {...textStyle}>CEP: {props.data.cep}</Text>
			<Text {...textStyle}>Rua: {props.data.street}</Text>
			<Text {...textStyle}>Bairro: {props.data.neighborhood}</Text>
			<Text {...textStyle}>Cidade: {props.data.city}</Text>
			<Text {...textStyle}>Estado: {props.data.state}</Text>
			<Text {...textStyle}>Serviço: {props.data.service}</Text>
			<Text {...textStyle}>
				Tipo de Localização: {props.data.location.type}
			</Text>
			<Text {...textStyle}>
				Longitude: {props.data.location.coordinates.longitude}
			</Text>
			<Text {...textStyle}>
				Latitude: {props.data.location.coordinates.latitude}
			</Text>
		</Box>
	);
};

export default CepResultV2;
