import {
	BankData,
	CepData,
	CnpjData,
	BanksData,
	CepDataV2,
} from '../utils/types';

import api from './apiConfig';

export const fetchCep = async (cep: string): Promise<CepData> => {
	const response = await api.get<CepData>(`/cep/${cep}`);
	return response.data;
};

export const fetchCepV2 = async (cep: string): Promise<CepDataV2> => {
	const response = await api.get<CepDataV2>(`/cep/v2/${cep}`);
	return response.data;
};

export const fetchCnpj = async (cnpj: string): Promise<CnpjData> => {
	const response = await api.get<CnpjData>(`/cnpj/${cnpj}`);
	return response.data;
};

export const fetchBank = async (bankId: string): Promise<BankData> => {
	const response = await api.get<BankData>(`/bank/${bankId}`);
	return response.data;
};

export const fetchBanks = async (): Promise<BanksData> => {
	const response = await api.get<BanksData>(`/banks`);
	return response.data;
};
