import { useCallback } from 'react';
import { handleErrorResponse } from '../utils/handleErrorResponse';
import { handleSuccessResponse } from '../utils/handleSuccessResponse';
import { fetchDataByOption } from '../services/searchService';
import { SuccessResult, ErrorResult } from './types';

export const useSearch = (
	selectedOption: string | null,
	setResult: (result: SuccessResult | ErrorResult) => void
) => {
	return useCallback(
		async (inputValue: string) => {
			if (!selectedOption) {
				setResult({
					success: false,
					message: 'Opção de consulta não selecionada.',
				});
				return;
			}

			try {
				const data = await fetchDataByOption(
					selectedOption,
					inputValue
				);
				const successResult = handleSuccessResponse(data);
				setResult(successResult);
			} catch (error: any) {
				const errorResult = handleErrorResponse(error);
				setResult(errorResult);
			}
		},
		[selectedOption, setResult]
	);
};
