import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { textStyle } from './styles';
import { BankResultProps } from './types';

const BankResult: React.FC<BankResultProps> = (props) => {
	return (
		<Box>
			<Text {...textStyle}>Código do Banco: {props.data.code}</Text>
			<Text {...textStyle}>Nome do Banco: {props.data.name}</Text>
			<Text {...textStyle}>Nome Completo: {props.data.fullName}</Text>
			<Text {...textStyle}>ISPB: {props.data.ispb}</Text>
		</Box>
	);
};

export default BankResult;
