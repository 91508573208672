import React from 'react';
import { List } from '@chakra-ui/react';
import { SidebarContainer, OptionItem, listStyle } from './styles';
import { SidebarProps } from './types';
import { ConsultaOption } from '../../utils/constants';

const Sidebar: React.FC<SidebarProps> = ({ setSelectedOption }) => {
	const options = [
		ConsultaOption.CEP,
		ConsultaOption.CEPV2,
		ConsultaOption.CNPJ,
		ConsultaOption.BANK,
		ConsultaOption.BANKS,
	];

	return (
		<SidebarContainer>
			<List {...listStyle}>
				{options.map((option) => (
					<OptionItem
						key={option}
						onClick={() => setSelectedOption(option)}
					>
						{option}
					</OptionItem>
				))}
			</List>
		</SidebarContainer>
	);
};

export default Sidebar;
