import { ErrorResult } from './types';

export const handleErrorResponse = (error: any): ErrorResult => {
	if (error.response) {
		const errorResponse = error.response.data;
		const errorMessage =
			errorResponse.error?.message || 'Erro ao realizar a consulta.';
		return { success: false, message: errorMessage };
	} else {
		return {
			success: false,
			message: 'Erro ao realizar a consulta. Verifique sua conexão.',
		};
	}
};
