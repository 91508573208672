export const boxStyle = {
	mt: 4,
	p: 4,
	borderWidth: 1,
	borderRadius: 'md',
};

export const headingStyle = {
	size: 'md',
};

export const textStyle = {
	mt: 4,
	whiteSpace: 'pre-wrap',
	color: '#E53E3E',
};
