import { ConsultaOption } from '../utils/constants';
import {
	fetchBank,
	fetchBanks,
	fetchCep,
	fetchCepV2,
	fetchCnpj,
} from './apiService';

export const fetchDataByOption = async (
	selectedOption: string,
	inputValue: string
): Promise<any> => {
	switch (selectedOption) {
		case ConsultaOption.CEP:
			return await fetchCep(inputValue);
		case ConsultaOption.CEPV2:
			return await fetchCepV2(inputValue);
		case ConsultaOption.CNPJ:
			return await fetchCnpj(inputValue);
		case ConsultaOption.BANK:
			return await fetchBank(inputValue);
		case ConsultaOption.BANKS:
			return await fetchBanks();
		default:
			throw new Error('Opção de consulta inválida');
	}
};
