import { ElementType } from 'react';

export const flexStyle = {
	height: '100vh',
};

export const boxStyle = {
	flex: '1',
	p: '5',
};

export const headingStyle = {
	as: 'h3' as ElementType,
	size: 'lg',
	mb: '4',
};
